export default {
  path: '/payments',
  children: [
    {
      path: 'success-pay',
      name: 'success-pay',
      component: () => import('@/views/payments/successPay.vue')
    },
    {
      path: 'error-in-pay',
      name: 'error-in-pay',
      component: () => import('@/views/payments/errorInPay.vue')
    },
  ]
}
