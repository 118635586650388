export default {
  path: '/recharge',
  children: [ 
    {
      path: 'phone/create',
      name: 'create-phone-recharge',
      component: () => import('@/views/recharges/CreatePhoneRecharge.vue')
    },
    {
      path: 'nauta/create',
      name: 'create-nauta-recharge',
      component: () => import('@/views/recharges/CreateNautaRecharge.vue')
    },
    {
      path: 'recharge-history',
      name: 'recharges-history',
      component: () => import('@/views/recharges/RechargesList.vue')
    }, 
  ],
  meta: {
    requiresAuth: true
  }
}
