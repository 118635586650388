import { useToast } from "vue-toast-notification";
import { FunctionsHttpError, FunctionsRelayError, FunctionsFetchError, AuthApiError } from "@supabase/supabase-js";

export async function errorToast(error) {
  const toast = useToast()
  if (typeof error === 'string') {
    console.log('!!!! error string', error)
    toast.error(error)
  } else if (error instanceof FunctionsHttpError) {
    const errorJson= await error.context.json()
    toast.error(errorJson.errorMessage)
  } else if (error instanceof AuthApiError){
    toast.error(`Auth Error: ${error.message}`)
  } else if (error instanceof FunctionsRelayError) {
    toast.error(`Relay error: ${error.message}`)
  } else if (error instanceof FunctionsFetchError) {
    toast.error(`Fetch error: ${error.message}`)
  } else if (error.message){
    toast.error(error.message)
  } else {
    toast.error('Ocurrió un error!')
  }
}

export function successToast(message){
  useToast().success(message)
}

export function warnToast(message){
  useToast().warning(message)
}