import { defineStore } from "pinia";
import { supabaseClient } from "@/supabase";
import { computed, onUnmounted, ref, watch } from "vue";
import { useUserStore } from "./user";
import { errorToast } from "@/utils";
import { get_FCM_token } from "../firebase";
import { v4 as uuidv4 } from "uuid";
export const useChatStore = defineStore("chat", () => {
  const messages = ref([]);
  const messagesLoaded = ref(false);
  const userStore = useUserStore();
  const supportUser = ref(null);

  let storagek_user_key;

  try {
    storagek_user_key = localStorage.getItem("uknow_uuid") ?? uuidv4();
    localStorage.setItem("uknow_uuid", storagek_user_key);
  } catch (e) {
    storagek_user_key = uuidv4();
    localStorage.setItem("uknow_uuid", storagek_user_key);
  }

  const randomKey = storagek_user_key;
  console.log(randomKey);

  console.log(randomKey);
  const token = ref(null);

  const GetSupportUser = async () => {
    const { error, data } = await supabaseClient
      .from("chat_support")
      .select("*")
      .eq("is_active", true);

    if (error) {
      errorToast(error.message);
      return null;
    }

    console.log(data, error);
    supportUser.value = data[Math.floor(Math.random() * data.length)];
    return supportUser.value;
  };

  async function getFCMToken(user_id) {
    let data = [];
    if (user_id) {
      const { data: fcm_data, error: user_err } = await supabaseClient
        .from("fcm_browser_tokens")
        .select("*")
        .eq("user_id", user_id ?? randomKey);

      if (user_err) {
        console.log(user_err);
        errorToast(user_err.message);
        return;
      }
      data = fcm_data;
    } else {
      const { data: cau_data, error: user_err } = await supabaseClient
        .from("chat_anonymous_users")
        .select("*")
        .eq("user_id", randomKey);
      data = cau_data;
      if (user_err) {
        console.log(user_err);
        errorToast(user_err.message);
        return;
      }
    }

    const already_exist = data.length > 0;

    try {
      const FCM_TOKEN = await get_FCM_token();
      token.value = FCM_TOKEN;

      if (already_exist) {
        if (user_id == null) {
          await supabaseClient
            .from("chat_anonymous_users")
            .update({
              browser_token: FCM_TOKEN,
            })
            .eq("user_id", randomKey);
        } else {
          const { error } = await supabaseClient
            .from("fcm_browser_tokens")
            .update({ token: FCM_TOKEN })
            .eq("user_id", user_id ?? randomKey);
          if (error) throw new Error("Error insertando al usuario");
        }
      } else {
        if (user_id == null) {
          const res = await supabaseClient.from("chat_anonymous_users").insert({
            user_id: randomKey,
            browser_token: FCM_TOKEN,
          });
          console.log(res);
        } else {
          const { error } = await supabaseClient
            .from("fcm_browser_tokens")
            .insert({ user_id: user_id ?? randomKey, token: FCM_TOKEN });
          if (error) throw new Error("Error actualizando al usuario");
        }
      }
    } catch (e) {
      errorToast(e.message);
      console.log(e);
    }
  }

  const GetMessages = async () => {
    console.log("obteniendo mensajes de: ", userStore.userId ?? randomKey);
    const { error, data } = await supabaseClient.functions.invoke(
      "get_chat_messages",
      {
        method: "POST",
        body: JSON.stringify({
          user_client_id: userStore.userId ?? randomKey,
          open_chat_user_id: userStore.userId ?? randomKey,
          page: 1,
          per_page: 300,
        }),
      },
    );
    if (error) {
      console.log(error);
      errorToast(error.message);
    } else {
      console.log(data);
      messages.value = data.messages.reverse();
      messagesLoaded.value = true;
    }
  };

  const SendMessage = async (message, user_id, support_id) => {
    messagesLoaded.value = false;

    messages.value.push({
      message,
      receiver_id: support_id,
      sender_id: user_id ?? randomKey,
      type: "SUPPORT",
    });

    const resp = await supabaseClient.functions.invoke("send_new_message", {
      method: "POST",
      body: {
        type: "USER_TO_SYSTEM",
        message: message,
        from: user_id ?? randomKey,
        to: support_id,
      },
    });
    console.log(resp);
    return resp;
  };

  const Messages = computed(() => {
    return messages.value.map((m) => ({
      ...m,
      ownMessage: (userStore.userId ?? randomKey) === m.sender_id,
    }));
  });

  const IsMessagesLoaded = computed(() => messagesLoaded.value);

  const has_token = computed(() => token.value != null);

  return {
    GetMessages,
    SendMessage,
    IsMessagesLoaded,
    Messages,
    GetSupportUser,
    getFCMToken,
    has_token,
    messages,
    supportUser,
  };
});
