import "./assets/main.css";

import { useChatStore } from "@/stores/chat";
import { useUserStore } from "@/stores/user";
import { createApp } from "vue";
import { createPinia } from "pinia";

import * as Sentry from "@sentry/vue";
import { SupabaseIntegration } from "@supabase/sentry-js-integration";
import { supabaseClient } from "./supabase";

import App from "./App.vue";
import router from "./router";

import "./index.css";
import "vue-toast-notification/dist/theme-sugar.css";

import { addIcons } from "oh-vue-icons";
import {
  IoArrowBackCircle,
  FaKey,
  FaMoneyBillWave,
  IoLogOut,
  IoClose,
  RiInstagramFill,
  BiFacebook,
  RiWhatsappFill,
  BiChevronDown,
  HiArrowRight,
  FcGoogle,
  BiApple,
  CoFacebookF,
  RiLinkedinFill,
  BiEye,
  BiEyeSlash,
  BiLayoutSidebar,
  HiSolidSearch,
} from "oh-vue-icons/icons";
import { SENTRY_ENV } from "./environment";
addIcons(
  IoArrowBackCircle,
  FaKey,
  FaMoneyBillWave,
  IoLogOut,
  IoClose,
  RiInstagramFill,
  BiFacebook,
  RiWhatsappFill,
  BiChevronDown,
  HiArrowRight,
  FcGoogle,
  BiApple,
  CoFacebookF,
  RiLinkedinFill,
  BiEye,
  BiEyeSlash,
  BiLayoutSidebar,
  HiSolidSearch,
);

const app = createApp(App);

app.use(router);
app.use(createPinia());

Sentry.init({
  app,
  dsn: "https://ac4a8dae506eb1c3d510b7a4634ebca7@o4507143191658496.ingest.us.sentry.io/4507143194542080",
  environment: SENTRY_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    new SupabaseIntegration(supabaseClient, {
      tracing: true,
      breadcrumbs: true,
      errors: true,
    }),
  ],
  // Performance Monitoring
  // tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount("#app");

// Escuchar cambios de visibilidad de la página
document.addEventListener("visibilitychange", () => {
  const chatStore = useChatStore();
  if (document.visibilityState === "visible") {
    chatStore.GetMessages();
  } else {
  }
});

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data && event.data.type === "NOTIFICATION_RECEIVED") {
      handleNotificationReceived(event.data.payload);
    }
  });
  console.log(navigator);
}

function handleNotificationReceived(payload) {
  const chatStore = useChatStore();
  const user = useUserStore();
  chatStore.messages.push({
    message: payload.notification.body,
    receiver_id: user.id,
    sender_id: chatStore.supportUser.id,
  });
}
