export default {
  path: '',
  children: [
    {
      path: '/service-request/:id',
      name: 'service-request',
      component: ()=> import('@/views/ServiceRequestView.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Solicitud de Servicio'
      }
    },
    {
      path: '/service-request-ended',
      name: 'service-request-ended',
      component: ()=> import('@/views/ServiceRequestEndedView.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Solicitud de Servicio'
      }
    }
  ]
}