<script setup>
  import { useLoadingStore } from '@/stores/loading'

  const loading = useLoadingStore()
</script>

<template>
  <Teleport to="body">
    <div v-show="loading.isLoading" class="fixed z-[9999] bg-gradient-to-tr top-0 right-0 left-0 bottom-0 flex place-content-center place-items-center" style="background-image: radial-gradient( var(--primary-color),  transparent );">
      <div class="flex flex-col gap-4 place-items-center p-10" >
        <div class="border-white border-2 rounded-full w-16 h-16 animate-spin relative">
          <div class="rounded-full bg-white w-4 h-4 absolute top-1/2 -left-1.5"></div>
          <div class="rounded-full bg-white w-4 h-4 absolute bottom-1/2 -right-1.5"></div>
        </div>
        <p class="text-white font-bold">CARGANDO</p>
      </div>
    </div>
  </Teleport>
</template>
