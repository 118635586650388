
export default {
  path: 'store',
  component: () => import('@/views/Store/index.vue'),
  children: [
    {
      path: 'products',
      name: 'shop-products',
      component: () => import('@/views/Store/products.vue')
    },
    {
      path: ':id',
      name: 'product-detail',
      component: () => import('@/views/Store/product-detail.vue')
    }
  ],
  meta: {
    requiresAuth: true
  }
}