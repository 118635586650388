export const environment = {
  // SUPABASE_URL: `https://okryxlfewrzzgspgvedt.supabase.co`,
  // ANON_KEY: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9rcnl4bGZld3J6emdzcGd2ZWR0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTE1MDI4NjgsImV4cCI6MjAwNzA3ODg2OH0.oyNGdCdlQjEOss8SgCJvfMjgt2Aj9WDyxzhlFmmyjO0`
  SUPABASE_URL: `https://ucbwgvvavnitnjaefhyc.supabase.co`,
  ANON_KEY: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVjYndndnZhdm5pdG5qYWVmaHljIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTg2NzkzNjYsImV4cCI6MjAxNDI1NTM2Nn0.JIzroGt5_-twUtdq8ty7Wo7lmkShPJRe2UQRi7yLRBI`,
};

export const SHOP_TENANT_ID = "a6864e3f-db54-4c8c-a22c-5b8541823088";
export const GIFT_CARDS_TENANT_ID = "bd1705be-7c64-48e1-a4ff-27a58ef751e0";
export const RECHARGES_TENANT_ID = "323ecdb2-d8c8-4eed-85fc-14333a0a6acf";

export const CHAPORT_ID = "65c52f90119e5b03160ef70c";

export const SENTRY_ENV = "development";

export const push_uid_name = "DARA";

export const public_push_key =
  "BPzH1SbY4LvZGB9SisLUCYAyk2W8TrCgrJZXQeq-IOIY0nxL8NEWqmj3vO6Q82MtZjdlitzSQxwpWxbGzr0zoXk";

