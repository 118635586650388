import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { public_push_key } from "./environment";
import { useChatStore } from "./stores/chat";
import { useUserStore } from "./stores/user";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBzxoeGGZEn5gHaN3C2rN8TkCKIx8pzTEI",
  authDomain: "dara-s-services.firebaseapp.com",
  projectId: "dara-s-services",
  storageBucket: "dara-s-services.appspot.com",
  messagingSenderId: "310083685141",
  appId: "1:310083685141:web:6a411eda9a2bee7a77b71c",
  measurementId: "G-JJMS1ELX52",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export async function get_FCM_token() {
  return await getToken(messaging, {
    vapidKey: public_push_key,
  });
}

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  const chatStore = useChatStore();
  const user = useUserStore();
  chatStore.messages.push({
    message: payload.notification.body,
    receiver_id: user.id,
    sender_id: chatStore.supportUser.id,
  });
});
