<template>
  <div class="fixed z-50 bottom-4 right-1 sm:right-4 h-full flex justify-end items-end">
    <!-- Chat Window -->
    <transition name="slide-fade">
      <div
        v-if="isChatOpen"
        class="bg-white border mb-10  rounded-xl overflow-hidden shadow-lg mt-2 box-border chat-ui w-96 h-3/4"
      >
        <!-- Chat Header -->
        <div
          class="flex flex-col py-5 h-1/3 gap-4 items-center justify-between mb-4 bg-blue-500 p-4"
        >
        <div>

          <h3 class="text-center text-xl text-white">
            Servicio de atención al Cliente
          </h3>
          <p class="flex justify-center text-gray-200/80 flex-row gap-1 items-center">
            <div class="p-1 w-1 animate-heartbeat h-1 bg-lime-400 rounded-full"></div> En línea
          </p>
          </div>


          <div class="flex flex-row gap-4">

            <picture class="flex flex-col gap-1 justify-center items-center" >
            <img class="w-14 h-14 rounded-full overflow-hidden" src="/customer-service.png" alt="">
          <p class="flex justify-center text-xs text-center text-gray-200/80 flex-row gap-1 items-center">
                      Atención al cliente
              </p>
            </picture>


            <picture class="flex flex-col gap-1 justify-center items-center" >
            <img class="w-14 h-14 rounded-full bg-white p-1 overflow-hidden" src="/logo.png" alt="">
          <p class="flex justify-center text-xs text-center text-gray-200/80 flex-row gap-1 items-center">
                Dara Services
              </p>
            </picture>



          </div>
          <p class="flex justify-center text-md text-center text-gray-100 flex-row gap-1 items-center">
                      Estamos en línea y listos para ayudarte              </p>
        </div>
        <!-- Chat Body -->
        <div class="flex flex-col h-2/3  p-4">
          <div class="flex-1 overflow-y-auto mb-4" ref="messagesContainer">
            <!-- Messages -->
            <div class="mb-2 animate-messaje" v-for="message in chatStore.Messages" :key="message">
              <div class=" p-2 rounded-lg" :class="{'bg-blue-200':message.ownMessage,'bg-gray-100':!message.ownMessage}" >
                <p class="text-gray-700">                {{ message.ownMessage ? "Tu" : "Soporte" }}</p>
                <p class="text-gray-500 text-sm text-justify break-words">
                  {{message.message}}
                </p>
              </div>
            </div>
          </div>
          <!-- Chat Input -->
          <div class="flex justify-between  flex-row box-border mb-5 items-center gap-4  border-b-0 border-x-0 border border-t-gray-200  ">

          <textarea
            type="text"
              v-model="currentMessage"
            placeholder="Escriba su mensaje aquí"
               @keydown.enter="sendMessage"
            class=" rounded-full w-full px-4 py-2 focus:outline-none"
              rows="2"
          />
            <button @click="sendMessage" v-if="!loading" class="transform rotate-45 text-zinc-500/40">

              <i class="text-xl pi pi-send"></i></button>
            <i class="pi pi-spin pi-spinner text-zinc-500/50" v-else style="font-size: 1.5rem"></i>
          </div>
        </div>
      </div>
    </transition>
    <!-- Chat Button -->
    <div
      class="bg-blue-500 fixed z-50 bottom-4 right-4 text-white transition-all duration-300 p-2 rounded-full focus:outline-none shadow-lg"
    >
      <button v-if="!isChatOpen" @click="setVisible"> <i class="text-xl px-2 py-1 pi pi-comment"></i></button>
      <button
        v-else
        @click="toggleChat"
        class="text-gray-500 hover:text-gray-700"
      >
        <p class="text-white font-extrabold mx-2 text-3xl">&times;</p>
      </button>
    </div>
  </div>
</template>

<script setup>
import { useChatStore } from "@/stores/chat.js";
import { computed, onMounted, ref, watch } from "vue";
import modalSlider from "@/components/common/modalSlider.vue";
import coolButton from "@/components/common/coolButton.vue";
import { useUserStore } from "@/stores/user.js";
import { useRouter } from "vue-router";
import { get_FCM_token } from "../firebase";
import { errorToast } from "@/utils";


const isChatOpen = ref(false);

const router = useRouter();
const userStore = useUserStore();
const chatStore = useChatStore();
const loading = ref(false)



const toggleChat = () => {
  isChatOpen.value = !isChatOpen.value;
};






const permission_granted = ref(false);
const supportUser = ref(null);

const currentMessage = ref("");

const messagesContainer = ref();

const messages_decorator = computed(()=>{
  if(messagesContainer.value){
  messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
  }
  return chatStore.Messages
})

const sendMessage = async () => {
  // loading.value=true
  let message = currentMessage.value

  currentMessage.value = ""

  const resp = await chatStore.SendMessage(
    message,
    userStore.userId,
    supportUser.value.user_id,
  );
  if (resp.data && resp.data.succeed) {
  }else{
    console.log(resp.error)
  }
  // loading.value=false
};

const setVisible = async () => {
  // if (!userStore.isLogued) {
  //   router.push({ name: "login" });
  //   return;
  // }

  isChatOpen.value = true;
  if (!permission_granted.value && chatStore.supportUser == null ) {
  loading.value=true
    permission_granted.value = (await requestPermission()) == "granted";
    await chatStore.getFCMToken(userStore.userId);
    supportUser.value = await chatStore.GetSupportUser();
    await chatStore.GetMessages();
  loading.value=false
  if(messagesContainer.value){
  messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
  }
  }
  loading.value=true
    supportUser.value = await chatStore.GetSupportUser();
  loading.value=false
};

watch(messages_decorator,()=>{
  if(messagesContainer.value){
  console.log(messagesContainer.value,'asasas')
    setTimeout(()=>{

  messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight ;
    },300)
  }
})

watch(isChatOpen, async (newValue) => {

  if (newValue && !chatStore.IsMessagesLoaded && chatStore.supportUser !=null) {
    await chatStore.GetMessages();
  if(messagesContainer.value){
  messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
  }
  }
});


async function requestPermission() {
  const permission = await Notification.requestPermission();
  return permission;
}


</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
.chat-ui {
  height: 90%;
}

@keyframes heartbeat {
  0%, 100% {
    background-color: #a3e635; /* bg-lime-400 */
    scale: 115%;
  }
  50% {
    background-color: #a3e16f; /* bg-lime-300 */
    scale: 100%;
    border: 0 0 0 10 #a3e3a3;
  }
}

.animate-heartbeat {
  animation: heartbeat 2s infinite;
}



@keyframes messaje {
   100% {
  opacity:1;
  transform:translateY(0%);
  }
  0% {
  opacity:0;
  transform:translateY(-100%);
  }
}

.animate-messaje{

}
  textarea {
            resize: none; /* Deshabilitar el redimensionamiento */
        }


</style>
