export default {
  path:'/auth',
  component: () => import('@/views/public/Auth/Auth.vue'),
  children: [
    {
      path: 'login',
      name: 'login',
      component: () => import('@/views/public/Auth/Login.vue'),
      meta: {
        pageTitle: 'Login'
      }
    },
    {
      path: 'register',
      name: 'register',
      component: () => import('@/views/public/Auth/Register.vue'),
      meta: {
        pageTitle: 'Registro'
      }
    },
    {
      path: ':id/confirm-user-register/',
      name: 'confirm-user-register',
      component: () => import('@/views/public/Auth/ConfirmUserRegister.vue'),
      meta: {
        pageTitle: 'ConfirmarEmail'
      }
    },
    {
      path: ':id/referal',
      name: 'referal',
      component: () => import('@/views/public/Auth/Referal.vue'),
      meta: {
        pageTitle: 'Referido'
      }
    },
    {
      path: 'send-recover-email',
      name: 'send-recover-email',
      component: () => import('@/views/public/Auth/SendRecoverEmail.vue')
    },
    {
      path: 'password-recover',
      name: 'password-recover',
      component: () => import('@/views/public/Auth/PasswordRecover.vue')
    }
  ]
}